:root {
    --clr-primary-550: #5a6baa;
    --clr-primary-400: hsl(9, 100%, 64%);
    --clr-primary-350: #8d9ed9;
    --clr-neutral-900: #0F172A;
    --clr-neutral-200: #E2E8F0;
    --clr-neutral-100: hsl(215, 20%, 65%);
  
    --ff-med: 'Inter-Medium',sans-serif;
  
    --fs-xl: 3rem;
    --fs-700: 2.5rem;
    --fs-600: 2.25rem;
    --fs-500: 1.25rem;
    --fs-450: 1.15rem;
    --fs-400: 1rem;
    --fs-350: 0.875rem;
    --fs-300: 0.75rem;
    --fs-200: 0.65rem;
  }
  * {
    box-sizing: border-box;
  }
  *::selection {
    background-color:var(--clr-primary-300); 
    color: var(--clr-neutral-900)
  }
  /*scroll bar for content formatting start*/
  .right-main-content::-webkit-scrollbar {
    width: 6px;
  }
  
  .right-main-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 7px;
  }
  
  .right-main-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 7px;
  }
  
  .right-main-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
   /*scroll bar for content formatting end*/
  body {
    background-color: var(--clr-neutral-900);
    font-size: var(--fs-400);
    color:var(--clr-neutral-100);
    font-family: 'Inter','Inter-Medium', sans-serif;
}
.empty-button{
    margin-left: .25rem;
    margin-right: .25rem;
    cursor: pointer;

}
@media (min-width: 1201px){
    .main{
        display: flex;
        align-items: top;
        justify-content: center;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        border: #5a6baa 1px solid;
        box-shadow: 0 0 10px #0c35c7;
        border-radius: 7px;
        padding: 1rem;
        gap: 1rem;
        height: 95vh;
        position: relative;
        overflow: auto;
    }
    /*left side*/
    .left-container{
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex:1;
        
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
    }
    .top-left-navigation{
    height: 2rem;
    text-align: center;
    font-weight: bolder;
    font-size: var(--fs-500);
    }   
    .left-main-img-container{
        display: flex;
        height: 100%;
        overflow: auto;
     
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
    }
    
    .left-main-img-container img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 7px;
    }
    .bottom-left-navigation{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: auto;
        height: 4rem;
        align-items: center;
        justify-content: center;

    }
    .bottom-left-navigation img{
        margin: .25rem;
        height: 3rem;
        width: 3rem;
        border-radius: 12px;
        object-fit: cover;
    }
    /*end of left side*/
    /*right side*/
    .right-container{
        width: 20rem;
        display: flex;
        flex-direction: column;
        overflow: auto;
        justify-content: space-between;
      
    }
    .top-right-navigation{
        height: 3rem;
       
        text-align: center;
        font-size: var(--fs-500);
    }
    .right-main-content{
    display: flex;
    flex-wrap: wrap;
    padding: .75rem;
    overflow: auto;
    font-size: var(--fs-400);
    align-items: flex-start;
    text-align:left;
    }
    .bottom-right-navigation{
        display: flex;
        flex-direction: column;
      
    }
    .pagination button{
        cursor: pointer;
        border-radius: 7px;
        padding: 0.25rem;
        margin-top: -.25rem;
        font-size: var(--fs-200);
        transition: var(--transition);
        background-color: var(--clr-primary-350);
        width: 4rem;
    }
    .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: var(--fs-200);
    }
    .page-numbers{
        margin-top: 1rem;
        margin-bottom: .25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .page-number{
        cursor: pointer;
        margin: 0.1rem;
        font-size: var(--fs-200);
        transition: var(--transition);
        
    }
    .page-count-box{
        
        display: flex;
        flex-direction: row;
        font-size: var(--fs-200);
        color: var(--clr-neutral-100);
    }
    .page-count{
        width: 4rem;
        margin-left: 1rem;
    }
}
@media (max-width: 1200px){
.main{
display: flex;
align-items: top;
justify-content: center;
margin-top: 1rem;
margin-left: 1rem;
margin-right: 1rem;
border: #5a6baa 1px solid;
box-shadow: 0 0 10px #0c35c7;
border-radius: 7px;
padding: 1rem;
gap: 1rem;
height: 95vh;
position: relative;
overflow: auto;
box-sizing: border-box;
}
/*left side*/
.left-container{
display: flex;
flex-direction: column;
overflow: auto;
flex:1;
justify-content: space-between;
box-sizing: border-box;
width: 100%;
}
.top-left-navigation{
height: 2rem;
text-align: center;
font-weight: bolder;
font-size: var(--fs-500);
}
.left-main-img-container{
    display: flex;
    height: 100%;
    overflow: auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    

}
.left-main-img-container img{
    width: 100%;
        height: 100%;
        object-fit: contain;
    border-radius: 7px;
}
.bottom-left-navigation{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    height: 4rem;
    align-items: center;
    justify-content: center;
}
.bottom-left-navigation img{
    margin: .25rem;
    height: 3rem;
    width: 3rem;
    border-radius: 12px;
    object-fit: cover;
}
/*end of left side*/
/*right side*/
.right-container{
    width: 20rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: space-between;
  
}
.top-right-navigation{
    height: 3rem;
    
    text-align: center;
    font-size: var(--fs-500);
}
.right-main-content{
display: flex;
flex-wrap: wrap;
padding: .75rem;
overflow: auto;
font-size: var(--fs-400);
align-items: flex-start;
text-align:left;
}
.bottom-right-navigation{
    display: flex;
    flex-direction: column;
    
}
.pagination button{
    cursor: pointer;
    border-radius: 7px;
    padding: 0.25rem;
    margin-top: -.25rem;
    font-size: var(--fs-200);
    transition: var(--transition);
    background-color: var(--clr-primary-350);
    width: 4rem;
}
.pagination{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-200);
}
.page-numbers{
    margin-top: 1rem;
    margin-bottom: .25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.page-number{
    cursor: pointer;
    margin: 0.1rem;
    font-size: var(--fs-200);
    transition: var(--transition);
    
}
.page-count-box{
    
    display: flex;
    flex-direction: row;
    font-size: var(--fs-200);
    color: var(--clr-neutral-100);
}
.page-count{
    width: 4rem;
    margin-left: 1rem;
}
/*end of right side*/
}
@media (max-width: 1024px){
    .main{
        display: flex;
        flex-direction: column;
        align-items: top;
        justify-content: center;
        margin-top: 1rem;
        margin-left: .25rem;
        margin-right: .25rem;
        border: #5a6baa 1px solid;
        box-shadow: 0 0 10px #0c35c7;
        border-radius: 7px;
    }
    /*left side*/
    .left-container{
        display: flex;
        text-align: center;
        flex-direction: column;
        width: 100%; 
        box-sizing: border-box; 
    }
    .top-left-navigation{
    height: 2rem;
    font-size: var(--fs-500);
    font-weight: bolder;
    }
    .left-main-img-container{
        display: flex;
        overflow: auto;
        align-content: center;
        justify-content: center;
        width: 100%; 
        box-sizing: border-box; 
        border-radius: 7px;
    }
    
    .left-main-img-container img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    border-radius: 7px;
    }
    .bottom-left-navigation{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: auto;
        height: 3.25rem;
        align-items: center;
        justify-content: center;
}
    .bottom-left-navigation img{
        margin: .2rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 12px;
        object-fit: cover;
    }
    /*end of left side*/
    /*right side*/
    .right-container{
        display: flex;
        flex-direction: column;
        overflow: auto;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
    }
    .top-right-navigation{
        height: .25rem;
        text-align: center;
      
        font-size: var(--fs-500);
    }
    .right-main-content{
        display: flex;
        flex-wrap: wrap;
        padding: .75rem;
        overflow: auto;
        font-size: var(--fs-400);
        height: 10rem;
        width: 100%;
        box-sizing: border-box;
    }
    .bottom-right-navigation{
        display: flex;
        flex-direction: column;
   
    }
    .pagination button{
        cursor: pointer;
        border-radius: 7px;
        padding: 0.25rem;
        margin-top: -.25rem;
        font-size: var(--fs-200);
        transition: var(--transition);
        background-color: var(--clr-primary-350);
        width: 4rem;
    }
    .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: var(--fs-200);
    }
    .page-numbers{
        margin-top: 1rem;
        margin-bottom: .25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .page-number{
        cursor: pointer;
        margin: 0.1rem;
        font-size: var(--fs-200);
        transition: var(--transition);
        
    }
    .page-count-box{
        
        display: flex;
        flex-direction: row;
        font-size: var(--fs-200);
        color: var(--clr-neutral-100);
    }
    .page-count{
        width: 4rem;
        margin-left: 1rem;
    }
    /*end of right side*/
}
@media (max-width: 480px){
    .main{
        display: flex;
        flex-direction: column;
        align-items: top;
        justify-content: center;
        margin-top: .25rem;
        margin-left: .25rem;
        margin-right: .25rem;
        border: #5a6baa 1px solid;
        box-shadow: 0 0 10px #0c35c7;
        border-radius: 7px;
        height: 89vh;
    }
    /*left side*/
    .left-container{
        display: flex;
        text-align: center;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: flex-start;
        height: 100%;
    }
    .top-left-navigation{
    height: 2rem;
    font-weight: bolder;
    font-size: var(--fs-500);
    }
    .left-main-img-container{
        display: flex;
        height: 15rem;
        align-content: center;
        justify-content: center;
    }
    
    .left-main-img-container img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 7px;
    }
    .bottom-left-navigation{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: auto;
        height: 4rem;
        padding-bottom: 2rem;
}
    .bottom-left-navigation img{
        margin: .2rem;
        width: 3rem;
        height: 3rem;
        border-radius: 12px;
    }
    /*end of left side*/
    /*right side*/
    .right-container{
        display: flex;
        flex-direction: column;
        overflow: auto;
        justify-content: space-between;
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        
    }
    .top-right-navigation{
        height: 0rem;
        text-align: center;
        font-size: var(--fs-450);
    }
    .right-main-content{
        display: flex;
        flex-wrap: wrap;
        padding: .75rem;
        overflow: auto;
        font-size: var(--fs-300);
        height: 20rem;
        width: 100%;
        border-radius: 7px;
        box-sizing: border-box;
        border: #5a6baa 1px solid;
        box-shadow: 0px 0px 5px #0c35c7;
    }
    .bottom-right-navigation{
        display: flex;
        flex-direction: column;
    }
    .pagination button{
        cursor: pointer;
        border-radius: 7px;
        padding: 0.25rem;
        margin-top: -.25rem;
        font-size: var(--fs-200);
        transition: var(--transition);
        background-color: var(--clr-primary-350);
        width: 4rem;
    }
    .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: var(--fs-200);
    }
    .page-numbers{
        margin-top: 1rem;
        margin-bottom: .25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .page-number{
        cursor: pointer;
        margin: 0.1rem;
        font-size: var(--fs-200);
        transition: var(--transition);
        
    }
    .page-count-box{
        
        display: flex;
        flex-direction: row;
        font-size: var(--fs-200);
        color: var(--clr-neutral-100);
    }
    .page-count{
        width: 4rem;
        margin-left: 1rem;
    }
    /*end of right side*/
}